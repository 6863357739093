<template>
  <vue-perfect-scrollbar
    :settings="{ suppressScrollX: true, wheelPropagation: false }"
    class="h-100 rtl-ps-none ps scroll"
    style="height: 100%"
  >
    <div class="pa-5 relative">
      <v-app-bar
        :color="$vuetify.theme.dark ? 'dark' : 'grey lighten-4'"
        :dark="$vuetify.theme.dark"
        class="pt-1"
        fixed
        flat
      >
        <div class="d-flex justify-space-between align-center w-full">
          <h6 class="ma-0">
            My Account
          </h6>
          <slot name="userDrawerCloseButton" />
        </div>
      </v-app-bar>
    </div>

    <div class="pa-5 mt-10">
      <div class="heading-label pa-0 mb-4">
        Profile
      </div>
      <div class="d-flex align-center mb-10">
        <v-avatar
          size="56"
          class="mr-2"
        >
          <img
            width="50"
            height="50"
            src="@/assets/images/avatars/001-man.svg"
            alt=""
          >
        </v-avatar>
        <div>
          <a
            href="#"
            class="link-alt"
          >
            <p class="font-weight-medium ma-0">
              Email: {{ loggedInUser ? loggedInUser.email : "Unauthorized" }}
            </p>
          </a>
          <v-divider />
          <p class="body-2 text--disabled mb-2">
            User ID: {{ loggedInUser ? loggedInUser.id : "Unassigned" }}
          </p>
          <p class="body-2 text--disabled mb-2">
            First Name: {{ loggedInUser ? loggedInUser.first_name : "Unauthorized" }}
          </p>
          <p class="body-2 text--disabled mb-2">
            Last Name: {{ loggedInUser ? loggedInUser.last_name : "Unauthorized" }}
          </p>
          <p class="body-2 text--disabled mb-2">
            Account ID: {{ loggedInUser ? loggedInUser.account_id : "Unassigned" }}
          </p>
          <p class="body-2 text--disabled mb-2">
            Platform ID: {{ loggedInUser ? loggedInUser.platform_id : "Unassigned" }}
          </p>
          <p class="body-2 text--disabled mb-2">
            Role ID: {{ loggedInUser ? loggedInUser.role_id : "Unassigned" }}
          </p>
          <v-btn
            small
            icon
            color=""
          >
            <v-icon
              small
              dense
            >
              mdi-google
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            color=""
          >
            <v-icon
              small
              dense
            >
              mdi-twitter
            </v-icon>
          </v-btn>
          <v-btn
            small
            icon
            color=""
          >
            <v-icon
              small
              dense
            >
              mdi-facebook
            </v-icon>
          </v-btn>
        </div>
      </div>
    </div>
  </vue-perfect-scrollbar>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserDrawer",
  props: {},
  data() {
    return {
      items: []
    };
  },
  computed: {
    ...mapGetters(["loggedInUser"]),
  },
  methods: {},
};
</script>

<style scoped></style>
